import Exam from "./../../../assets/icons/exam.svg";
import Result from "./../../../assets/icons/result.svg";
import programIcon from "./../../../assets/icons/program-icon.svg";
import dashboardIcon from "./../../../assets/icons/dashboard-icon.svg";
import assignmentIcon from "./../../../assets/icons/assignment-icon.svg";
import freeCourseIcon from "./../../../assets/icons/free-course-icon.svg";
import liveClassIcon from "./../../../assets/icons/live-classes-icon.svg";
import webinarIcon from "./../../../assets/icons/webinar-icon.svg";
import classroomIcon from "./../../../assets/icons/classroom-icon.svg";
import paymentIcon from "./../../../assets/icons/payment.svg";
import libraryIcon from "./../../../assets/icons/library-icon.svg";
import assignmentStatus from "./../../../assets/icons/assignment-status.png";
// library-icon.svg

const navBarData = [
    {
        id: 1,
        heading: "CURRICULUM",
        data: [
            {
                id: 11,
                img: dashboardIcon,
                text: "Dashboard",
                link: "/",
            },
            {
                id: 12,
                img: programIcon,
                text: "My Program",
                link: "/program/",
            },
            {
                id: 18,
                img: classroomIcon,
                text: "Classroom",
                link: "/classroom/",
            },
            {
                id: 13,
                img: liveClassIcon,
                text: "Live Classes",
                link: "/live-class/",
            },
            {
                id: 14,
                img: webinarIcon,
                text: "Webinars",
                link: "/webinar/",
            },
            {
                id: 15,
                img: assignmentIcon,
                text: "Assignments",
                link: "/assignments/",
            },
            {
                id: 16,
                img: assignmentStatus,
                text: "Assignments Status",
                link: "/assignments-status/",
            },
            {
                id: 17,
                img: Exam,
                text: "Exam",
                link: "/exam/",
            },
            {
                id: 18,
                img: Result,
                text: "Results",
                // link: "/results/",
                link: "/results?section=assignment&period=1",
            },
            {
                id: 19,
                img: classroomIcon,
                text: "Announcements",
                link: "/announcements/",
            },
            {
                id: 20,
                img: Result,
                text: "Free Courses",
                link: "/free-courses/",
            },

            // {
            // 	img: Dashboard,
            // 	text: "Projects",
            // 	link: "/superadmin/",
            // },
            // {
            // 	img: Dashboard,
            // 	text: "Assignments",
            // 	link: "/superadmin/",
            // },
        ],
    },
    {
        id: 2,
        heading: "MORE",
        data: [
            // {
            //     id: 21,
            //     img: freeCourseIcon,
            //     text: "Free Courses",
            //     link: "/free-courses/",
            // },
            {
                id: 21,
                img: freeCourseIcon,
                text: "FAQs",
                link: "/faq/",
            },

            {
                id: 22,
                img: paymentIcon,
                text: "Payments",
                link: "/payments/",
            },
            {
                img: libraryIcon,
                text: "Library",
                link: "/library/",
            },
            // {
            // 	img: Dashboard,
            // 	text: "Webinar",
            // 	link: "/superadmin/",
            // },
            // {
            // 	img: Dashboard,
            // 	text: "Projects",
            // 	link: "/superadmin/",
            // },
            // {
            // 	img: Dashboard,
            // 	text: "Assignments",
            // 	link: "/superadmin/",
            // },
        ],
    },
];
export default navBarData;
