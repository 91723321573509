import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BookModal from "./BookModal";
import downIcon from "../../../assets/icons/down-icon-bold.svg";
import upAndDownIcon from "../../../assets/icons/up-down-icon.svg";
import loadingIcon from "../../../assets/lottie/loading-buffering.gif";
import rightIcon from "../../../assets/icons/rounded-right-icon.svg";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

import Slider from "react-slick";
import Search from "../../includes/search/Search";
import Dropdown from "../../includes/dropdown/Dropdown";
import Tabs from "../../includes/tabs/Tabs";
import SingleBookCard from "./SingleBookCard";
import {
    FormControl,
    MenuItem,
    Pagination,
    Popover,
    Select,
} from "@mui/material";
import Loader from "../../includes/loader/Loader";
import LibraryPagination from "../../includes/LibraryPagination";

export default function Library() {
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState("All");
    const [isAllBooksLoading, setIsAllBooksLoading] = useState(false);
    const [allBooks, setAllBooks] = useState([]);
    const [isPopularBooksLoading, setIsPopularBooksLoading] = useState(false);
    const [popularBooks, setPopularBooks] = useState([]);
    const [isFavouriteBooksLoading, setIsFavouriteBooksLoading] =
        useState(false);
    const [favouriteBooks, setFavouriteBooks] = useState([]);
    const [selectedBookId, setSelectedBookId] = useState("");
    const [isSingleBookLoading, setIsSingleBookLoading] = useState(false);
    const [selectedBookData, setSelectedBookData] = useState({});
    const [relatedBooks, setRelatedBooks] = useState([]);

    const [search, setSearch] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [sliderImages, setSliderImages] = useState([]);
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isModal, setIsModal] = useState(false);

    const [categories, setCategories] = useState([]);

    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // scrolling categories
    const categoryButtonsContainer = useRef(null);
    const leftButton = useRef(null);
    const rightButton = useRef(null);

    const handleLeftScroll = () => {
        categoryButtonsContainer.current.scrollLeft -= 100;
    };

    const handleRightScroll = () => {
        categoryButtonsContainer.current.scrollLeft += 100;
    };

    useEffect(() => {
        const container = categoryButtonsContainer.current;
        if (!container) {
            return;
        }
        const handleScroll = () => {
            const canScrollLeft = container.scrollLeft > 0;
            // const canScrollRight =
            //     container.scrollWidth - container.clientWidth >
            //     container.scrollLeft;

            const canScrollRight =
                container.scrollWidth >
                container.clientWidth + container.scrollLeft;

            if (leftButton.current) {
                if (canScrollLeft) {
                    leftButton.current.removeAttribute("hidden");
                } else {
                    leftButton.current.setAttribute("hidden", true);
                }
            }
            if (rightButton.current) {
                if (
                    canScrollRight &&
                    container.clientWidth !== container.scrollWidth
                ) {
                    rightButton.current.removeAttribute("hidden");
                } else {
                    rightButton.current.setAttribute("hidden", true);
                }
            }
        };
        container.addEventListener("scroll", handleScroll);
        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [categories, categoryButtonsContainer, leftButton, rightButton]);

    // const [scrollAmount, setScrollAmount] = useState(0);
    // const categoryButtonsContainer = useRef(null);

    // function handleScroll(direction) {
    //     const container = categoryButtonsContainer.current;
    //     const containerWidth = container.offsetWidth;
    //     const containerScrollWidth = container.scrollWidth;
    //     const maxScroll = containerScrollWidth - containerWidth;
    //     let newScrollAmount = scrollAmount;

    //     if (direction === "right" && scrollAmount < maxScroll) {
    //         newScrollAmount += 100;
    //     } else if (direction === "left" && scrollAmount > 0) {
    //         newScrollAmount -= 100;
    //     }

    //     setScrollAmount(newScrollAmount);
    //     container.scrollTo({ left: newScrollAmount, behavior: "smooth" });
    // }

    // sort
    const sortByValues = [
        {
            id: 1,
            name: "Popular",
            value: "Popular",
        },
        {
            id: 2,
            name: "All",
            value: "All",
        },
    ];
    const [selectedSort, setSelectedSort] = useState(sortByValues[1].value);
    // Sort By Filter popover onclick
    const [sortByFilterAnchorEl, setSortByFilterAnchorEl] = useState(null);
    const sortByButtonRef = useRef(null);
    const handleClickSortByFilter = () => {
        setSortByFilterAnchorEl(sortByButtonRef.current);
    };
    const handleCloseSortByFilter = () => {
        setSortByFilterAnchorEl(null);
    };
    const openSortByFilter = Boolean(sortByFilterAnchorEl);
    const sortByFilterPopoverStyle = {
        position: "absolute",
        top: 30,
        left: -34,
        // right: 0,
    };
    // style for filter button
    // const sortByFilterPopoverStyle = {
    //     position: "absolute",
    //     top: 46,
    //     left: -34,
    //     // right: 0,
    // };

    const getAllBooksList = (type) => {
        if (type !== "withoutloading") {
            setIsAllBooksLoading(true);
        }
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    page: currentPage,
                    categories:
                        selectedCategories.length > 0
                            ? JSON.stringify(selectedCategories)
                            : [],
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setAllBooks(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setIsAllBooksLoading(false);
                        if (sortByFilterAnchorEl) {
                            handleCloseSortByFilter();
                        }
                    }, 50);
                } else {
                    setTimeout(() => {
                        setIsAllBooksLoading(false);
                        alert("It is taking longer than usual");
                    }, 200);
                }
            })

            .catch((error) => {});
    };
    const getPopularBooksList = (type) => {
        if (type !== "withoutloading") {
            setIsPopularBooksLoading(true);
        }
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/popular/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    page: currentPage,
                    categories:
                        selectedCategories.length > 0
                            ? JSON.stringify(selectedCategories)
                            : [],
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPopularBooks(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setIsPopularBooksLoading(false);
                        if (sortByFilterAnchorEl) {
                            handleCloseSortByFilter();
                        }
                    }, 50);
                } else {
                    setTimeout(() => {
                        setIsPopularBooksLoading(false);
                        alert("It is taking longer than usual");
                    }, 200);
                }
            })
            .catch((error) => {});
    };
    const getFavouriteBooksList = (type) => {
        if (type !== "withoutloading") {
            setIsFavouriteBooksLoading(true);
        }
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/student-favourite-books/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    page: currentPage,
                    categories:
                        selectedCategories.length > 0
                            ? JSON.stringify(selectedCategories)
                            : [],
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFavouriteBooks(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setIsFavouriteBooksLoading(false);
                    }, 50);
                } else {
                    setTimeout(() => {
                        alert("It is taking longer than usual");
                        setIsFavouriteBooksLoading(false);
                    }, 200);
                }
            })

            .catch((error) => {});
    };
    const getRelatedbooks = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/${selectedBookId}/related/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setRelatedBooks(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        alert("It is taking longer than usual");
                    }, 200);
                }
            })

            .catch((error) => {});
    };

    const getSingleBook = (type) => {
        if (type !== "withoutloading") {
            setIsSingleBookLoading(true);
        }
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/${selectedBookId}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSelectedBookData(data);
                    setTimeout(() => {
                        setIsSingleBookLoading(false);
                    }, 50);
                } else {
                    setTimeout(() => {
                        alert("It is taking longer than usual");
                        setIsSingleBookLoading(false);
                    }, 200);
                }
            })

            .catch((error) => {});
    };

    const getSliderImages = (id) => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/book/banner/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSliderImages(data);
                    // setTimeout(() => {
                    //     setLoading(false);
                    // }, 200);
                } else {
                    // setTimeout(() => {
                    // setLoading(false);
                    alert("It is taking longer than usual");
                    // }, 200);
                }
            })

            .catch((error) => {});
    };

    const getCategories = (id) => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/library/category/select`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCategories(data);
                } else {
                    setTimeout(() => {
                        alert(
                            "It is taking longer than usual to load categories"
                        );
                    }, 200);
                }
            })

            .catch((error) => {});
    };

    const handleFavourite = async (bookId) => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("book_id", bookId);
        try {
            baseConfig
                .post(`/library/student-favourite-books/`, formdata, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        // "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        if (selectedTab == "Favourites") {
                            getFavouriteBooksList("withoutloading");
                        }
                        if (isModal) {
                            getSingleBook("withoutloading");
                            getRelatedbooks("withoutloading");
                        }
                        if (selectedTab == "All") {
                            if (selectedSort == "All") {
                                getAllBooksList("withoutloading");
                            } else {
                                getPopularBooksList("withoutloading");
                            }
                        }
                    } else {
                        alert("Some error occured");
                    }
                })
                .catch((error) => {});
        } catch (error) {
            alert("Some error occured");
        }
    };

    useEffect(() => {
        if (selectedTab == "All") {
            if (selectedSort == "All") {
                getAllBooksList();
            } else {
                getPopularBooksList();
            }
        } else {
            getFavouriteBooksList();
        }
    }, [selectedCategories, search, selectedSort, selectedTab, currentPage]);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (selectedBookId) {
            getSingleBook();
            getRelatedbooks();
        }
    }, [selectedBookId]);

    useEffect(() => {
        getSliderImages();
    }, []);

    return (
        <MainContainer>
            <BookModal
                isModal={isModal}
                setIsModal={setIsModal}
                isSingleBookLoading={isSingleBookLoading}
                selectedBookId={selectedBookId}
                setSelectedBookId={setSelectedBookId}
                selectedBookData={selectedBookData}
                handleFavourite={handleFavourite}
                getSingleBook={getSingleBook}
                relatedBooks={relatedBooks}
            />
            {sliderImages.length > 0 && (
                <SliderSection>
                    <Slider {...settings}>
                        {sliderImages.map((sliderObject) => (
                            <SlideContainer
                                key={sliderObject.id}
                                onClick={() => {
                                    setSelectedBookId(sliderObject?.book);
                                    setIsModal(true);
                                }}
                            >
                                <Slide
                                    src={sliderObject.website_banner}
                                    alt="Image"
                                />
                            </SlideContainer>
                        ))}
                        {/* <SlideContainer>
                        <Slide src={slide2} alt="Image" />
                    </SlideContainer>
                    <SlideContainer>
                        <Slide src={slide3} alt="Image" />
                    </SlideContainer> */}
                    </Slider>
                </SliderSection>
            )}

            <MiddleSection>
                <SelectDiv>
                    <ProgramButtonDiv>
                        <ProgramButton
                            background={
                                selectedTab === "All" ? "#fff" : "#F5F5F5"
                            }
                            boxShadow={
                                selectedTab === "All"
                                    ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                    : "none"
                            }
                            // style={{ marginRight: "10px" }}
                            onClick={() => {
                                if (currentPage != "1") {
                                    setCurrentPage("1");
                                }
                                setSelectedTab("All");
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedTab === "All"
                                        ? "#404040"
                                        : "#9D9D9D"
                                }
                            >
                                All
                            </ButtonName>
                        </ProgramButton>
                        <Border>|</Border>

                        <ProgramButton
                            background={
                                selectedTab === "Favourites"
                                    ? "#fff"
                                    : "#F5F5F5"
                            }
                            boxShadow={
                                selectedTab === "Favourites"
                                    ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                    : "none"
                            }
                            onClick={() => {
                                if (currentPage != "1") {
                                    setCurrentPage("1");
                                }
                                setSelectedTab("Favourites");
                            }}
                        >
                            <ButtonName
                                color={
                                    selectedTab === "Favourites"
                                        ? "#404040"
                                        : "#9D9D9D"
                                }
                            >
                                Favourites
                            </ButtonName>
                        </ProgramButton>
                    </ProgramButtonDiv>
                </SelectDiv>

                <SearchDiv>
                    <Search
                        search={search}
                        setSearch={setSearch}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </SearchDiv>

                <DropDownContainer>
                    {selectedTab == "All" && (
                        <>
                            <DropDownDiv>
                                <SubTitle>Sort by</SubTitle>
                                <SelectBox
                                    filterOpen={openSortByFilter}
                                    ref={sortByButtonRef}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClickSortByFilter();
                                    }}
                                >
                                    <SelectedText>{selectedSort}</SelectedText>
                                    <DownIcon src={downIcon} alt="icon" />
                                </SelectBox>
                            </DropDownDiv>
                            <Popover
                                style={sortByFilterPopoverStyle}
                                open={openSortByFilter}
                                anchorEl={sortByFilterAnchorEl}
                                onClose={handleCloseSortByFilter}
                                sx={{
                                    "&& .MuiPopover-paper": {
                                        border: "1px solid #e7e7e7",

                                        boxShadow:
                                            "8px 16px 44px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "12px",
                                    },
                                }}
                            >
                                <SortByFilterMainBox>
                                    {sortByValues.map((item) => (
                                        <FilterItem
                                            key={item.id}
                                            onClick={() => {
                                                if (currentPage != "1") {
                                                    setCurrentPage("1");
                                                }
                                                setSelectedSort(item.value);
                                            }}
                                        >
                                            <Round>
                                                <InnerRound
                                                    selected={
                                                        selectedSort ==
                                                        item.value
                                                    }
                                                ></InnerRound>{" "}
                                            </Round>
                                            <FilterItemName>
                                                {item.name}
                                            </FilterItemName>
                                        </FilterItem>
                                    ))}
                                </SortByFilterMainBox>
                            </Popover>
                        </>
                    )}
                    {/* <FilterButton
                        filterOpen={openSortByFilter}
                        ref={sortByButtonRef}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClickSortByFilter();
                        }}
                    >
                        <FilterButtonText>Sort by</FilterButtonText>
                        <UpDownIcon src={upAndDownIcon} alt="icon" />
                    </FilterButton> */}
                </DropDownContainer>
            </MiddleSection>
            <CategorySection>
                {categories.length > 0 && (
                    <CategoriesButtonWrapper>
                        <CategoriesLeftButton
                            id="left-button"
                            ref={leftButton}
                            hidden
                            // onClick={() => handleScroll("left")}
                            onClick={handleLeftScroll}
                            // style={{ display: canScrollLeft ? "block" : "none" }}
                        >
                            <ArrowIcon
                                src={rightIcon}
                                style={{
                                    transform: "rotate(180deg)",
                                    // width: "30px",
                                }}
                                alt="icon"
                            />
                        </CategoriesLeftButton>
                        <CategoryButtonsContainer
                            ref={categoryButtonsContainer}
                        >
                            <Tabs
                                data={categories}
                                setSelectedCategories={setSelectedCategories}
                                selectedCategories={selectedCategories}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </CategoryButtonsContainer>
                        <CategoriesRightButton
                            id="right-button"
                            ref={rightButton}
                            hidden
                            // onClick={() => handleScroll("right")}
                            onClick={handleRightScroll}
                            // style={{ display: canScrollRight ? "block" : "none" }}
                        >
                            <ArrowIcon
                                src={rightIcon}
                                style={
                                    {
                                        // transform: "rotate(180deg)",
                                        // width: "30px",
                                    }
                                }
                                alt="icon"
                            />
                        </CategoriesRightButton>
                    </CategoriesButtonWrapper>
                )}
            </CategorySection>
            <DetailedSection>
                {selectedTab == "All" ? (
                    <>
                        {selectedSort == "All" ? (
                            <>
                                {!isAllBooksLoading ? (
                                    <>
                                        {allBooks.length > 0 ? (
                                            <>
                                                <BooksSection>
                                                    {allBooks.map((item, i) => (
                                                        <SingleBookCard
                                                            isModal={isModal}
                                                            setIsModal={
                                                                setIsModal
                                                            }
                                                            data={item}
                                                            setSelectedBookId={
                                                                setSelectedBookId
                                                            }
                                                            selectedBookData={
                                                                selectedBookData?.content_file
                                                            }
                                                            key={item.id}
                                                            handleFavourite={
                                                                handleFavourite
                                                            }
                                                        />
                                                    ))}
                                                </BooksSection>
                                                <LibraryPagination
                                                    paginationData={
                                                        paginationData
                                                    }
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    currentPage={currentPage}
                                                />
                                            </>
                                        ) : (
                                            <BooksLoaderContainer>
                                                <NoResultText>
                                                    No Books Found
                                                </NoResultText>
                                            </BooksLoaderContainer>
                                        )}
                                    </>
                                ) : (
                                    <BooksLoaderContainer>
                                        <LoaderIcon
                                            src={loadingIcon}
                                            alt="icon"
                                        />
                                    </BooksLoaderContainer>
                                )}
                            </>
                        ) : (
                            <>
                                {!isPopularBooksLoading ? (
                                    <>
                                        {popularBooks.length > 0 ? (
                                            <>
                                                <BooksSection>
                                                    {popularBooks?.map(
                                                        (item) => (
                                                            <SingleBookCard
                                                                isModal={
                                                                    isModal
                                                                }
                                                                setIsModal={
                                                                    setIsModal
                                                                }
                                                                data={item}
                                                                setSelectedBookId={
                                                                    setSelectedBookId
                                                                }
                                                                selectedBookData={
                                                                    selectedBookData?.content_file
                                                                }
                                                                key={item.id}
                                                                handleFavourite={
                                                                    handleFavourite
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </BooksSection>
                                                <LibraryPagination
                                                    paginationData={
                                                        paginationData
                                                    }
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    currentPage={currentPage}
                                                />
                                            </>
                                        ) : (
                                            <BooksLoaderContainer>
                                                <NoResultText>
                                                    No Books Found
                                                </NoResultText>
                                            </BooksLoaderContainer>
                                        )}
                                    </>
                                ) : (
                                    <BooksLoaderContainer>
                                        <LoaderIcon
                                            src={loadingIcon}
                                            alt="icon"
                                        />
                                    </BooksLoaderContainer>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {!isFavouriteBooksLoading ? (
                            <>
                                {favouriteBooks.length > 0 ? (
                                    <>
                                        <BooksSection>
                                            {favouriteBooks?.map((item) => (
                                                <SingleBookCard
                                                    type="favourite"
                                                    isModal={isModal}
                                                    setIsModal={setIsModal}
                                                    data={item}
                                                    setSelectedBookId={
                                                        setSelectedBookId
                                                    }
                                                    selectedBookData={
                                                        selectedBookData?.content_file
                                                    }
                                                    key={item.id}
                                                    handleFavourite={
                                                        handleFavourite
                                                    }
                                                />
                                            ))}
                                        </BooksSection>
                                        <LibraryPagination
                                            paginationData={paginationData}
                                            setCurrentPage={setCurrentPage}
                                            currentPage={currentPage}
                                        />
                                    </>
                                ) : (
                                    <BooksLoaderContainer>
                                        <NoResultText>
                                            No Books Found
                                        </NoResultText>
                                    </BooksLoaderContainer>
                                )}
                            </>
                        ) : (
                            <BooksLoaderContainer>
                                <LoaderIcon src={loadingIcon} alt="icon" />
                            </BooksLoaderContainer>
                        )}
                    </>
                )}
            </DetailedSection>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 34px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;
`;
const SliderSection = styled.div`
    margin-bottom: 67px;

    .slick-dots li.slick-active button:before {
        color: #522f91;
        display: block;
        width: 100%;
        height: 100%;
        font-size: 10px;
    }

    .slick-dots li button:before {
        color: #917afd;
        display: block;
        width: 100%;
        height: 100%;
        font-size: 8px;
        opacity: 1;
    }
`;
const SlideContainer = styled.div`
    width: 100%;
    height: 370px;
    cursor: pointer;
    border-radius: 16px !important;
`;
const Slide = styled.img`
    display: block;
    border-radius: 16px !important;
    width: 99%;
    height: 100%;
    /* object-fit: contain; */
`;
const CategorySection = styled.div``;
const DetailedSection = styled.div``;
const MiddleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SelectDiv = styled.div`
    display: flex;
`;
const ProgramButtonDiv = styled.div`
    width: 235px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 6px 8px;
    border-radius: 12px;
    @media all and (max-width: 1180px) {
        width: 190px;
    }
`;
const ProgramButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#F5F5F5"};
    padding: 8px 8px;
    min-width: 80px;
    border-radius: 8px;
    height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    @media all and (max-width: 1180px) {
        min-width: 50px;
        padding: 8px 2px;
    }
    /* box-shadow: ${(props) =>
        props.boxShadow ? props.boxShadow : "none"}; */
`;
const Border = styled.span`
    font-size: 1rem;
    color: #e7e7e7;
    display: inline-block;
    margin: 0 10px;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#9D9D9D")};
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0 10px;
    text-align: center;
`;
const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 7px;
    border-radius: 10px;
`;
const DashboardButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#F5F5F5"};
    padding: 9px 6px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : "none")};
`;
// const ButtonName = styled.h3`
//     color: ${(props) => (props.color ? props.color : "#9D9D9D")};
//     font-size: 0.95rem;
//     font-weight: 500;
//     padding: 0 10px;
//     z-index: 1;
// `;
// const Border = styled.span`
//     font-size: 1rem;
//     color: #e7e7e7;
//     display: inline-block;
//     margin: 0 10px;
// `;
const SearchDiv = styled.div`
    display: flex;
    align-items: center;
    width: 600px;

    @media all and (max-width: 1280px) {
        width: 600px;
    }
    @media all and (max-width: 1080px) {
        width: 500px;
    }
    @media all and (max-width: 968px) {
        width: 350px;
    }
`;
const DropDownContainer = styled.div`
    width: 170px;
    display: flex;
    justify-content: flex-end;
    @media all and (max-width: 1300px) {
        width: 100px;
    }
`;
const DropDownDiv = styled.div`
    /* width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center; */
`;
const DTitle = styled.span`
    color: #010101;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
`;

const CategoriesButtonWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    position: relative;
`;
const CategoriesLeftButton = styled.div`
    position: absolute;
    left: -20px;
    /* top: 50%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: transparent;
    border: none;
    color: gray;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        color: black;
    }
`;
const CategoryButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Add for smooth scrolling on iOS */
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const CategoriesRightButton = styled.div`
    position: absolute;
    right: -20px;
    /* top: 50%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: transparent;
    border: none;
    color: gray;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        color: black;
    }
`;
const ArrowIcon = styled.img`
    width: 30px;
    display: block;
`;

const BooksSection = styled.div`
    width: 100%;
    margin-top: 42px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 20px;
    @media (max-width: 1800px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;
const SubTitle = styled.span`
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    color: #010101;
`;
const SelectBox = styled.div`
    width: fit-content;
    max-width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fff;
    cursor: pointer;
    height: 32px;
    box-sizing: border-box;
`;
const SelectedText = styled.span`
    display: block;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #404040;
`;
const DownIcon = styled.img`
    width: 10px;
    display: block;
    margin-left: 40px;
`;
const SortByFilterMainBox = styled.div`
    background: #ffffff;
    /* border: 1px solid #e7e7e7;
    box-shadow: 8px 16px 44px rgba(0, 0, 0, 0.25);
    border-radius: 12px; */
    width: 230px;
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
`;
const FilterItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const Round = styled.div`
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    padding: 2px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #9d9d9d;
`;
const InnerRound = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.selected ? "#917AFD" : "#fff")};
`;
const FilterItemName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
const FilterButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 4px 12px;
    height: 44px;
    cursor: pointer;
    border: ${(props) =>
        props.filterOpen ? "1px solid #522e92" : "1px solid #e7e7e7"};
    &:hover {
        border: 1px solid #522e92;
    }
`;
const FilterButtonText = styled.span`
    display: block;
    color: #000000;
    margin-right: 12px;
`;
const UpDownIcon = styled.img`
    display: block;
    width: 14px;
`;
const BooksLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
`;
const LoaderIcon = styled.img`
    width: 40px;
    height: 40px;
    display: block;
`;
const NoResultText = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
`;
