import React, { useState, useEffect, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";

// import "./Dashboard.css";
import NavBar from "./navBar";
import styled from "styled-components";
// import MainDashboard from "./MainDashboard";
import navBarData from "./NavBarData";
import MainDashboard2 from "./MainDashboard2";
import Dashboard from "./Dashboard";
import Program from "../programs/Program";
import Assignments from "../assignments/Assignments";
import SubmitAssignment from "../assignments/SubmitAssignment";
import AssignmentStatus from "../assignmentStatus/AssignmentStatus";
import MySubjects from "../programs/MySubjects";
import Subject from "../programs/Subject";
import TopBar from "./TopBar";
import Profile from "../profile/Profile";
import Notifications from "../notifications/Notifications";
import FreeCourse from "../freeCourses/FreeCourse";
import LiveClasses from "../liveClass/LiveClasses";
import Webinar from "../webinar/Webinar";
import UpcomingWebinar from "../webinar/UpcomingWebinar";
import Faq from "../faq/Faq";
import ClassroomSingle from "../classroom/ClassroomSingle";
import Payments from "../payments/Payments";
import Library from "../library/Library";
import ReadPdf from "../library/ReadPdf";
import PdfComponent from "../library/PdfComponent";
import NotFoundPage from "../../includes/NotFoundPage";
import Result from "../results/Result";
import { TopUpContext } from "../../../contexts/TopUpContext/TopUpStore";
import Announcements from "../announcements/Announcements";
import {
    GetTopUpProgramConst,
    // GetLog,
} from "./../../includes/functions/TopUpFunctions";
import FreeCourseView from "../freeCourses/FreeCourseView";

function Index() {
    const { state } = useContext(Context);
    const { topUpState, topUpDispatch } = useContext(TopUpContext);

    const [isLoading, setLoading] = useState(false);
    const [universityModel, setUniversityModel] = useState("");
    const [student, setStudent] = useState({});
    const getStudentsDetails = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`students/student-profile/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudent(data.data);
                    setUniversityModel(data.university_model);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (error.response.status == 500) {
                    alert("Server error,Contact LMS");
                }
            });
    };

    useEffect(() => {
        getStudentsDetails();
        GetTopUpProgramConst(state, topUpDispatch);
    }, []);

    return (
        // <Router>
        !isLoading ? (
            <MainContainer>
                <Div>
                    <NavBar
                        data={navBarData}
                        universityModel={universityModel}
                        student={student}
                    />

                    <RouterMainDiv>
                        <TopBar student={student} />
                        {/* <Switch> */}
                        {/* <Route path="/" element={<MainDashboard />} /> */}
                        {/* <Redirect exact from="/" to="/exam/" />
						<Route path="/exam" component={MainDashboard2} /> */}
                        {/* <Route exact path="/" component={Dashboard} /> */}
                        {/* </Switch> */}
                        <Switch>
                            <Route
                                exact={true}
                                path="/"
                                render={() => <Dashboard student={student} />}
                                // render={() => <div>haai</div>}
                            />

                            {/* Profile */}
                            <Route
                                path="/profile"
                                // component={Profile}
                                render={() => (
                                    <Profile
                                        student={student}
                                        getStudentsDetails={getStudentsDetails}
                                    />
                                )}

                                // getSudentsDetails={getSudentsDetails()}
                            />

                            {/* Programs */}
                            <Route exact path="/program/" component={Program} />
                            <Route
                                path="/program/subject/:id"
                                component={MySubjects}
                            />
                            {/* <Route path="/programs/subject/" component={Subject} /> */}

                            {/* Assignments */}
                            <Route
                                exact
                                path="/assignments/"
                                component={Assignments}
                            />
                            <Route
                                path="/assignments/submit/:id"
                                component={SubmitAssignment}
                            />

                            {/* Assignment status */}
                            <Route
                                exact
                                path="/assignments-status/"
                                component={AssignmentStatus}
                            />

                            {/* Exams */}
                            <Route
                                exact
                                path="/exam/"
                                // component={MainDashboard2}

                                render={() => (
                                    <MainDashboard2 student={student} />
                                )}
                            />
                            {/* Announcements */}
                            <Route
                                exact
                                path="/announcements/"
                                component={Announcements}
                            />

                            {/* Free Courses */}
                            <Route
                                exact
                                path="/free-courses/"
                                component={FreeCourse}
                            />

                            {/* Live Classes */}
                            <Route
                                exact
                                path="/live-class/"
                                component={LiveClasses}
                            />

                            {/* Webinars */}
                            <Route exact path="/webinar/" component={Webinar} />
                            <Route
                                path="/webinar/upcoming/"
                                component={UpcomingWebinar}
                            />

                            {/* Notifications */}
                            <Route
                                exact
                                path="/notifications/"
                                component={Notifications}
                            />

                            {/* FAQ */}
                            <Route exact path="/faq/" component={Faq} />

                            {/* Classroom */}
                            <Route
                                exact
                                path="/classroom/"
                                component={ClassroomSingle}
                            />

                            {/* Payments */}
                            <Route
                                exact
                                path="/payments/"
                                component={Payments}
                            />

                            {/* library */}
                            <Route exact path="/library/" component={Library} />
                            <Route
                                path="/library/readpdf/:id"
                                // component={ReadPdf}
                                component={PdfComponent}
                            />
                            <Route
                                path="/free-courses/:id"
                                // component={ReadPdf}
                                component={FreeCourseView}
                            />

                            {/* Results */}

                            <Route
                                exact
                                path="/results/"
                                render={() => (
                                    <Result universityModel={universityModel} />
                                )}

                                // component={Result}
                            />
                            <Redirect
                                from="/results/"
                                to="/results?section=assignment&period=1"
                            />

                            {/* Page not found */}
                            <Route component={NotFoundPage} />
                            {/* <Route path="/error/" component={NotFoundPage} /> */}
                        </Switch>
                    </RouterMainDiv>
                </Div>
            </MainContainer>
        ) : null
        // </Router>
    );
}

export default Index;

const MainContainer = styled.div`
    padding: 20px;
    background: #f9f9f9;
    // height: 100vh;
    // display: flex;
`;
const RouterMainDiv = styled.div`
    // padding: 30px;
    // background: #f9f9f9;
    // height: 100vh;
    margin-left: 320px;
    @media (max-width: 1500px) {
        margin-left: 70px;
    }
`;
const Div = styled.div`
    // padding: 30px;
    // background: #f9f9f9;
    // height: 100vh;
`;
