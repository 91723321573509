import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import downIcon from "../../../assets/icons/down-icon-bold.svg";
import FreeCourseCard from "../../includes/freeCourse/FreeCourseCard";
import Loader from "../../includes/loader/Loader";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import { baseConfig, devConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import Search from "../../includes/search/Search";
import { Popover } from "@mui/material";

export default function FreeCourse() {
    const { state } = useContext(Context);
    const sortByButtonRef = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState("All");
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [freeCourseData, setFreeCourseData] = useState([]);
    const [categories, setCategories] = useState([]);
    const accessToken = state.user_details.access_token;

    const sortByValues = [
        {
            id: 1,
            name: "Popular",
            value: "Popular",
        },
        {
            id: 2,
            name: "All",
            value: "All",
        },
    ];
    const [selectedSort, setSelectedSort] = useState(sortByValues[1].value);
    const [sortByFilterAnchorEl, setSortByFilterAnchorEl] = useState(null);
    const handleClickSortByFilter = () => {
        setSortByFilterAnchorEl(sortByButtonRef.current);
    };
    const handleCloseSortByFilter = () => {
        setSortByFilterAnchorEl(null);
    };
    const openSortByFilter = Boolean(sortByFilterAnchorEl);
    const sortByFilterPopoverStyle = {
        position: "absolute",
        top: 30,
        left: -34,
    };

    useEffect(() => {
        const getFreeCourseData = () => {
            setLoading(true);
            baseConfig
                .get("/students/fee-courses/", {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;

                    if (StatusCode === 6000) {
                        setFreeCourseData(data);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((error) => {});
        };
        getFreeCourseData();
    }, []);

    useEffect(() => {
        const getCategories = () => {
            baseConfig
                .get(`/free-courses/categories/`, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setCategories(data);
                    } else {
                        setTimeout(() => {
                            alert(
                                "It is taking longer than usual to load categories"
                            );
                        }, 200);
                    }
                })

                .catch((error) => {});
        };
        getCategories();
    }, []);

    const handleEnroll = async (bookId) => {
        var formdata = new FormData();
        formdata.append("course", bookId);
        try {
            baseConfig
                .post(`/students/enroll-free-course/`, formdata, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        window.location.reload();
                    } else {
                        alert("Some error occured");
                    }
                })
                .catch((error) => {});
        } catch (error) {
            alert("Some error occured");
        }
    };

    return (
        <MainContainer>
            {!isLoading ? (
                <>
                    <FreeCourseText>Free Course</FreeCourseText>
                    <CategoryTextWrapper>
                        {/* <CategoryIcon src={categoryIcon} alt="icon" />
                        <CategoryText>All Free Courses</CategoryText> */}
                        <MiddleSection>
                            <SelectDiv>
                                <ProgramButtonDiv>
                                    <ProgramButton
                                        background={
                                            selectedTab === "All"
                                                ? "#fff"
                                                : "#F5F5F5"
                                        }
                                        boxShadow={
                                            selectedTab === "All"
                                                ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                                : "none"
                                        }
                                        // style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            if (currentPage != "1") {
                                                setCurrentPage("1");
                                            }
                                            setSelectedTab("All");
                                        }}
                                    >
                                        <ButtonName
                                            color={
                                                selectedTab === "All"
                                                    ? "#404040"
                                                    : "#9D9D9D"
                                            }
                                        >
                                            All
                                        </ButtonName>
                                    </ProgramButton>
                                    <Border>|</Border>

                                    <ProgramButton
                                        background={
                                            selectedTab === "Favourites"
                                                ? "#fff"
                                                : "#F5F5F5"
                                        }
                                        boxShadow={
                                            selectedTab === "Favourites"
                                                ? "box-shadow: 0px 8px 29px rgba(0, 0, 0, 0.05)"
                                                : "none"
                                        }
                                        onClick={() => {
                                            if (currentPage != "1") {
                                                setCurrentPage("1");
                                            }
                                            setSelectedTab("Favourites");
                                        }}
                                    >
                                        <ButtonName
                                            color={
                                                selectedTab === "Favourites"
                                                    ? "#404040"
                                                    : "#9D9D9D"
                                            }
                                        >
                                            Favourites
                                        </ButtonName>
                                    </ProgramButton>
                                </ProgramButtonDiv>
                            </SelectDiv>

                            <SearchDiv>
                                <Search
                                    search={search}
                                    setSearch={setSearch}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </SearchDiv>

                            <DropDownContainer>
                                {selectedTab == "All" && (
                                    <>
                                        <DropDownDiv>
                                            <SubTitle>Sort by</SubTitle>
                                            <SelectBox
                                                filterOpen={openSortByFilter}
                                                ref={sortByButtonRef}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleClickSortByFilter();
                                                }}
                                            >
                                                <SelectedText>
                                                    {selectedSort}
                                                </SelectedText>
                                                <DownIcon
                                                    src={downIcon}
                                                    alt="icon"
                                                />
                                            </SelectBox>
                                        </DropDownDiv>
                                        <Popover
                                            style={sortByFilterPopoverStyle}
                                            open={openSortByFilter}
                                            anchorEl={sortByFilterAnchorEl}
                                            onClose={handleCloseSortByFilter}
                                            sx={{
                                                "&& .MuiPopover-paper": {
                                                    border: "1px solid #e7e7e7",

                                                    boxShadow:
                                                        "8px 16px 44px rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "12px",
                                                },
                                            }}
                                        >
                                            <SortByFilterMainBox>
                                                {sortByValues.map((item) => (
                                                    <FilterItem
                                                        key={item.id}
                                                        onClick={() => {
                                                            if (
                                                                currentPage !=
                                                                "1"
                                                            ) {
                                                                setCurrentPage(
                                                                    "1"
                                                                );
                                                            }
                                                            setSelectedSort(
                                                                item.value
                                                            );
                                                        }}
                                                    >
                                                        <Round>
                                                            <InnerRound
                                                                selected={
                                                                    selectedSort ==
                                                                    item.value
                                                                }
                                                            ></InnerRound>{" "}
                                                        </Round>
                                                        <FilterItemName>
                                                            {item.name}
                                                        </FilterItemName>
                                                    </FilterItem>
                                                ))}
                                            </SortByFilterMainBox>
                                        </Popover>
                                    </>
                                )}
                            </DropDownContainer>
                        </MiddleSection>
                    </CategoryTextWrapper>
                    {freeCourseData.length > 0 ? (
                        <ContentWrapper>
                            {freeCourseData.map((freeCourse) => (
                                <SingleCategorizedContent key={freeCourse.id}>
                                    {/* <FreeCourseItemsWrapper> */}
                                    <FreeCourseCard
                                        item={freeCourse}
                                        handleEnroll={handleEnroll}
                                    />
                                    {/* </FreeCourseItemsWrapper> */}
                                </SingleCategorizedContent>
                            ))}
                        </ContentWrapper>
                    ) : (
                        <EmptyContainer>
                            <EmptyPage
                                img={emptyPic}
                                message={"Sorry, nothing here"}
                            />
                        </EmptyContainer>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 25px 8px 25px 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    overflow-y: scroll;
`;
const FreeCourseText = styled.h5`
    font-size: 1.1rem;
    color: #000000;
    font-weight: 500;
`;
const ContentWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const SingleCategorizedContent = styled.div`
    margin-bottom: 25px;
    width: 420px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const CategoryTextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;
const CategoryIcon = styled.img`
    height: 20px;
    object-fit: contain;
`;
const CategoryText = styled.h6`
    font-size: 0.95rem;
    color: #404040;
    margin-left: 20px;
`;
const FreeCourseItemsWrapper = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    @media (max-width: 1500px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    /* @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
    } */
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    /* @media (max-width: 840px) {
        grid-template-columns: 1fr;
    } */
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 180px);
    overflow-y: scroll;
    /* border: 1px solid #000; */
`;

const MiddleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
const SelectDiv = styled.div`
    display: flex;
`;
const ProgramButtonDiv = styled.div`
    width: 235px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    padding: 6px 8px;
    border-radius: 12px;
    @media all and (max-width: 1180px) {
        width: 190px;
    }
`;
const ProgramButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#F5F5F5"};
    padding: 8px 8px;
    min-width: 80px;
    border-radius: 8px;
    height: 36px;
    box-sizing: border-box;
    cursor: pointer;
    @media all and (max-width: 1180px) {
        min-width: 50px;
        padding: 8px 2px;
    }
`;
const Border = styled.span`
    font-size: 1rem;
    color: #e7e7e7;
    display: inline-block;
    margin: 0 10px;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#9D9D9D")};
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0 10px;
    text-align: center;
`;

const SearchDiv = styled.div`
    display: flex;
    align-items: center;
    width: 600px;

    @media all and (max-width: 1280px) {
        width: 600px;
    }
    @media all and (max-width: 1080px) {
        width: 500px;
    }
    @media all and (max-width: 968px) {
        width: 350px;
    }
`;
const DropDownContainer = styled.div`
    width: 170px;
    display: flex;
    justify-content: flex-end;
    @media all and (max-width: 1300px) {
        width: 100px;
    }
`;
const DropDownDiv = styled.div``;

const SubTitle = styled.span`
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    color: #010101;
`;
const SelectBox = styled.div`
    width: fit-content;
    max-width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #fff;
    cursor: pointer;
    height: 32px;
    box-sizing: border-box;
`;
const SelectedText = styled.span`
    display: inline-block;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
    color: #404040;
`;
const DownIcon = styled.img`
    width: 10px;
    display: block;
    margin-left: 40px;
`;
const SortByFilterMainBox = styled.div`
    background: #ffffff;
    width: 230px;
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
`;
const FilterItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const Round = styled.div`
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    padding: 2px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #9d9d9d;
`;
const InnerRound = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.selected ? "#917AFD" : "#fff")};
`;
const FilterItemName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
