import React, { useState, useContext, useEffect } from "react";
import { Context } from "./../../../contexts/Store";
import { baseConfig } from "./../../../axiosConfig";
import Loader from "../../includes/loader/Loader";
import styled from "styled-components";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/live-class-empty-icon.svg";
import { toast, Toaster } from 'sonner';

const Announcements = () => {
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);

    const [announcements, setAnnouncements] = useState([]);
    const [expandedAnnouncements, setExpandedAnnouncements] = useState({});

    const toggleReadMore = (index) => {
        setExpandedAnnouncements((prevExpanded) => ({
            ...prevExpanded,
            [index]: !prevExpanded[index],
        }));
    };

    const getAnnouncements = async () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;

        try {
            const response = await baseConfig.get("/campus/published-announcements/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            });

            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                setAnnouncements(data);
            } else {
                toast.error("Failed to fetch announcements");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnnouncements();
    }, []);

    const htmlcontent = (content) => {
        return `<style>
                    p {
                        margin-top: 10px;
                        color: black !important;
                    }
                </style>
        ${content}`;
    };

    return (
        <MainContainer>
            <Toaster richColors closeButton position='top-right'/>
            {!isLoading ? (
                <>
                    {announcements.length > 0 ? (
                        <>
                            <SectionTitleContainer>
                                <SectionTitle>Announcements</SectionTitle>
                            </SectionTitleContainer>
                            <AssignmentsListContainer>
                                <AnnouncementsCards>
                                    {announcements.map((announcement, index) => (
                                        <Card key={index}>
                                            <CardTop>
                                                <CardHeading>{announcement.title}</CardHeading>
                                                {
                                                    announcement.published_date ? (
                                                        <DateContainer>
                                                            <Date>{announcement.published_date}</Date>
                                                            {/* <StatusBadge isUpcoming={new Date(announcement.published_date) > new Date()}>
                                                                {new Date(announcement.published_date) > new Date() ? "Upcoming" : "Past"}
                                                            </StatusBadge> */}
                                                        </DateContainer>
                                                    ) : null
                                                }
                                            </CardTop>
                                            <CardContent>
                                                <div
                                                    className="htmlTag"
                                                    style={{ color: "black" }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: !expandedAnnouncements[index]
                                                            ? htmlcontent(announcement.description).slice(0, 300)
                                                            : htmlcontent(announcement.description)
                                                    }}
                                                />
                                                {announcement.description.length > 300 && (
                                                    <ReadMore onClick={() => toggleReadMore(index)}>
                                                        {expandedAnnouncements[index] ? " Read less" : " Read more"}
                                                    </ReadMore>
                                                )}
                                            </CardContent>
                                        </Card>
                                    ))}
                                </AnnouncementsCards>
                            </AssignmentsListContainer>
                        </>
                    ) : (
                        <EmptyContainer>
                            <EmptyPage img={emptyPic} message="Sorry, nothing here" />
                        </EmptyContainer>
                    )}
                </>
            ) : (
                <Loader />
            )}
        </MainContainer>
    );
};

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 15px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
`;

const SectionTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 20px;
    padding-right: 5px;
`;

const SectionTitle = styled.h3`
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 10px;
`;

const AssignmentsListContainer = styled.div`
    max-height: calc(100vh - 240px);
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 10px 8px 10px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 8px;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    }
`;

const EmptyContainer = styled.div`
    height: calc(100vh - 145px);
    overflow-y: scroll;
`;

const AnnouncementsCards = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

const Card = styled.li`
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 24px;
    width: 100%; // Updated to take full width
`;

const CardTop = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #010101;
`;

const CardHeading = styled.h5`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;

const DateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px; // Adds spacing between the badges
`;

const Date = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background-color: #5c3795;
    color: #ffffff;
    padding: 4px 12px;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
`;

const StatusBadge = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    background-color: ${(props) => (props.isUpcoming ? "#28a745" : "#dc3545")};
    color: #ffffff;
    padding: 4px 12px;
    border-radius: 12px;
    display: inline-block;
    text-align: center;
`;

const CardContent = styled.p`
    color: #9d9d9d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const ReadMore = styled.span`
    color: #4a96ef;
    font-size: 15px;
    cursor: pointer;
`;

const htmlTags = styled.p`
color: black;
`

export default Announcements;