import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../axiosConfig";
import styled from "styled-components";
import ProgramVideoSection from "../../includes/programsCommonComponents/singlePageBasedOnType/ProgramVideoSection";
import Loader from "../../includes/loader/Loader";
import EmptyPage from "../../includes/emptyPages/EmptyPage";
import emptyPic from "../../../assets/icons/webinar-empty-icon.svg";

function FreeCourseView() {
    const { state } = useContext(Context);
    const accessToken = state.user_details.access_token;
    const [courseData, setCourseData] = useState([]);
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setLoading] = useState(true);

    console.log(courseData, "courseData");

    useEffect(() => {
        const viewCourseData = () => {
            baseConfig
                .get(`/students/enrolled-free-course-details/${id}`, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        setCourseData(data);
                    } else {
                        setTimeout(() => {
                            alert(
                                "It is taking longer than usual to load categories"
                            );
                            setLoading(false);
                        }, 200);
                    }
                })

                .catch((error) => {});
        };
        viewCourseData();
    }, []);

    return (
        <MainContainer>
            <TopContainer>
                <TextDiv>
                    <PathText
                        onClick={() => {
                            history.push("/free-courses/");
                        }}
                    >
                        Free Course
                    </PathText>
                    <Slash>&nbsp;/&nbsp;</Slash>
                    <PathTextBlack>{courseData.title}</PathTextBlack>
                </TextDiv>
            </TopContainer>
            {!isLoading ? (
                <ContentBox>
                    <LeftPart>
                        {/* <ProgramVideoSection
                            data={null}
                            subjectName={null}
                            updateContentStatus={updateContentStatus}
                            isNextPractice={isNextPractice}
                            setOpenPracticeModal={setOpenPracticeModal}
                            setOpenInitialModal={setOpenInitialModal}
                            practiceData={practiceData}
                        /> */}
                        <h1>hi</h1>
                    </LeftPart>
                    <RightPart>
                        <h1>hello</h1>
                    </RightPart>
                </ContentBox>
            ) : (
                <Loader />
            )}
        </MainContainer>
    );
}

export default FreeCourseView;

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    @media (max-width: 950px) {
        overflow-y: scroll;
    }
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const TextDiv = styled.div``;

const PathText = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
const Slash = styled.span`
    display: inline-block;
    font-size: 14px;
    color: #9d9d9d;
`;
const PathTextBlack = styled.span`
    color: #000;
    display: inline-block;
    font-size: 14px;
`;
const ContentBox = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    @media (max-width: 950px) {
        flex-wrap: wrap;
        /* max-height: calc(100vh - 200px);
        overflow-y: scroll; */
    }
`;
const LeftPart = styled.div`
    width: calc(100% - 415px);
    height: calc(100vh - 200px);
    box-sizing: border-box;
    overflow-y: scroll;
    /* border-radius: 6px; */
    /* &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
    @media (max-width: 1500px) {
        width: calc(100% - 360px);
    }
    @media (max-width: 1300px) {
        width: calc(100% - 310px);
    }
    @media (max-width: 950px) {
        width: 100%;
        max-height: none;
        overflow-y: visible;
        /* margin-bottom: 40px; */
    }
    /* @media (max-width: 1190px) {
        width: calc(100% - 250px);
    } */
    /* border: 1px solid #e5e5e5; */
`;

const RightPart = styled.div`
    width: 400px;
    /* padding-right: 5px; */
    /* border: 1px solid #ededed; */
    /* border-right: none; */
    /* border-radius: 10px; */
    /* min-height: 480px; */
    max-height: calc(100vh - 200px);
    border-radius: 0 0 10px 10px;
    overflow-y: scroll;
    transition: 0.5s;
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    }
    @media (max-width: 1500px) {
        width: 350px;
    }
    @media (max-width: 1300px) {
        width: 300px;
    }
    @media (max-width: 950px) {
        /* display: none; */
        /* max-height: auto !important; */
        max-height: none;
        overflow-y: scroll;
        width: 100%;
        margin-top: 30px;
    }
    @media (max-height: 700px) {
        /* display: none; */
        /* max-height: auto !important; */
        max-height: calc(100vh - 220px);
    }
    /* @media (max-width: 1190px) {
        width: 250px;
    } */
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 200px);
    overflow-y: scroll;
`;
